module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/src/components/Layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"en","start_url":"/","theme_color_in_head":false,"icon":"src/assets/favicon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"cacheDigest":"cbd3a4a323836e06096d69b3b0efef63"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
