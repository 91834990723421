// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-writing-samples-js": () => import("./../../../src/pages/writing-samples.js" /* webpackChunkName: "component---src-pages-writing-samples-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-voice-js": () => import("./../../../src/templates/voice.js" /* webpackChunkName: "component---src-templates-voice-js" */),
  "component---src-templates-writing-js": () => import("./../../../src/templates/writing.js" /* webpackChunkName: "component---src-templates-writing-js" */),
  "component---src-templates-writing-sample-js": () => import("./../../../src/templates/writing-sample.js" /* webpackChunkName: "component---src-templates-writing-sample-js" */)
}

